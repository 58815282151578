<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate"
        >
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
      <v-col md="12">
        <v-form
          ref="itemForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <v-tabs
            v-model="itemTab"
            background-color="transparent"
            color="blue"
            class="custom-tab-transparent"
            active-class="blue darken-4 text-white"
            hide-slider
          >
            <v-tab href="#overview"> Overview </v-tab>
            <v-tab href="#images"> Images </v-tab>
            <v-tabs-items v-model="itemTab">
              <v-tab-item value="overview">
                <v-container fluid class="pt-0">
                  <v-row>
                    <v-col md="6">
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-name-english" class="btx-label mt-2 required"
                            >Name (In English)</label
                          >
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <TextInput
                            hide-details
                            v-model="item.name_en"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="item-name-english"
                            :rules="[vrules.required(item.name_en, 'Name (In English)')]"
                            :class="{ required: !item.name_en }"
                            placeholder="Name (In English)"
                          ></TextInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="py-1">
                          <label for="item-description" class="btx-label mt-2">Description</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <TextAreaInput
                            hide-details
                            v-model="item.description"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="item-description"
                            placeholder="Description"
                          ></TextAreaInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-price" class="btx-label mt-2">Price</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <CurrencyInput
                            hide-details
                            v-model="item.amount"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="item-price"
                            placeholder="Price"
                          ></CurrencyInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="is-sale" class="btx-label mt-2">Sale</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <v-layout>
                            <v-flex md1>
                              <v-checkbox
                                hide-details
                                v-model="item.sale"
                                class="mt-3 py-0"
                                id="is-sale"
                              ></v-checkbox>
                            </v-flex>
                            <v-flex md11>
                              <CurrencyInput
                                hide-details
                                :disabled="!item.sale || pageLoading"
                                v-model="item.sale_amount"
                                :loading="pageLoading"
                                id="item-sale-amount"
                                placeholder="Sale Amount"
                              ></CurrencyInput>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-sale-duration" class="btx-label mt-2"
                            >Sale Duration</label
                          >
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <DateRangePicker
                            hide-details
                            :disabled="!item.sale || pageLoading"
                            v-model="item.sale_duration"
                            :loading="pageLoading"
                            id="item-sale-duration"
                            placeholder="Sale Duration"
                          ></DateRangePicker>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col md="6">
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-name-chinese" class="btx-label mt-2 required"
                            >Name (In Chinese)</label
                          >
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <TextInput
                            hide-details
                            v-model="item.name_ch"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="item-name-chinese"
                            :rules="[vrules.required(item.name_ch, 'Name (In Chinese)')]"
                            :class="{ required: !item.name_ch }"
                            placeholder="Name (In Chinese)"
                          ></TextInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-cuisine" class="btx-label mt-2">Cuisine</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <AutoCompleteInput
                            hide-details
                            v-model="item.cuisine"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="listCuisine"
                            item-text="name"
                            item-value="name"
                            id="item-cuisine"
                            placeholder="Cuisine"
                          ></AutoCompleteInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-category" class="btx-label mt-2">Category</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <AutoCompleteInput
                            hide-details
                            v-model="item.category"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="listCategory"
                            item-text="name"
                            item-value="name"
                            id="item-category"
                            placeholder="Category"
                            v-on:change="getSubCategories()"
                          ></AutoCompleteInput>
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="listSubCategory.length">
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-sub-category" class="btx-label mt-2">Sub Category</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <AutoCompleteInput
                            hide-details
                            v-model="item.sub_category"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="listSubCategory"
                            item-text="name"
                            item-value="name"
                            id="item-sub-category"
                            placeholder="Sub Category"
                          ></AutoCompleteInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="item-add-on-category" class="btx-label mt-2"
                            >Add On Category</label
                          >
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <AutoCompleteInput
                            hide-details
                            v-model="item.add_on_category"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items="listAddOnCategory"
                            item-text="name"
                            item-value="name"
                            id="item-add-on-category"
                            placeholder="Add On Category"
                          ></AutoCompleteInput>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex md4 class="my-auto py-1">
                          <label for="is-reward" class="btx-label mt-2">Reward</label>
                        </v-flex>
                        <v-flex md8 class="py-1">
                          <v-layout>
                            <v-flex md1>
                              <v-checkbox
                                hide-details
                                v-model="item.reward"
                                class="mt-3 py-0"
                                id="is-reward"
                              ></v-checkbox>
                            </v-flex>
                            <v-flex md11>
                              <NumberInput
                                hide-details
                                :disabled="!item.reward || pageLoading"
                                v-model="item.reward_points"
                                :loading="pageLoading"
                                id="item-reward-points"
                                placeholder="Reward Points"
                                suffix="POINTS / QTY"
                              ></NumberInput>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="images">
                <v-container class="item-images" fluid>
                  <v-row>
                    <v-col md="4" class="border-light-grey">
                      <v-chip
                        color="green lighten-1"
                        label
                        outlined
                        class="position-absolute remove-radius bg-white"
                      >
                        Primary
                      </v-chip>
                      <ImageTemplate
                        :src="primaryImage()"
                        custom-class="border-light-grey"
                      ></ImageTemplate>
                    </v-col>
                    <v-col md="8" class="py-0">
                      <v-row>
                        <template v-for="(image, index) in images">
                          <v-hover v-slot="{ hover }" :key="`hover-${index}`">
                            <v-col
                              md="2"
                              sm="3"
                              class="border-light-grey"
                              :key="`image-block-${index}`"
                            >
                              <ImageTemplate :src="image.url"></ImageTemplate>
                              <v-layout v-if="hover" class="image-actions">
                                <v-flex class="absolute-top-left">
                                  <v-tooltip top content-class="custom-top-tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        :disabled="pageLoading"
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        color="red lighten-1"
                                        depressed
                                        tile
                                        v-on:click="removeImage(index)"
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span> Delete </span>
                                  </v-tooltip>
                                </v-flex>
                                <v-flex class="absolute-top-right" v-if="!image.primary">
                                  <v-tooltip top content-class="custom-top-tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        :disabled="pageLoading"
                                        v-bind="attrs"
                                        v-on="on"
                                        icon
                                        color="blue darken-4"
                                        depressed
                                        tile
                                        v-on:click="markAsPrimary(index)"
                                      >
                                        <v-icon>mdi-check-all</v-icon>
                                      </v-btn>
                                    </template>
                                    <span> Mark as Primary </span>
                                  </v-tooltip>
                                </v-flex>
                              </v-layout>
                            </v-col>
                          </v-hover>
                        </template>
                        <v-col md="2" sm="3" class="border-light-grey">
                          <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            ref="fileInput"
                            class="d-none"
                            multiple
                            v-on:change="handleFilesUpload()"
                          >
                          </v-file-input>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-bind="attrs"
                                v-on="on"
                                icon
                                color="blue darken-4"
                                depressed
                                class="upload-button"
                                tile
                                v-on:click="fileInput()"
                              >
                                <v-icon class="upload-icon d-contents"
                                  >mdi-cloud-upload-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span> Upload Images </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-form>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import NumberInput from "@/view/components/NumberInput";
import ImageTemplate from "@/view/components/Image";
import CurrencyInput from "@/view/components/CurrencyInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { UPDATE_FILES } from "@/core/lib/upload.lib";
import {
  GET_CATEGORY,
  GET_CUISINE,
  GET_SUB_CATEGORY,
  GET_ADD_ON_CATEGORY,
} from "@/core/lib/common.lib";
import { GET_ITEM, CREATE_ITEM, UPDATE_ITEM } from "@/core/lib/item.lib";
import { concat, toSafeInteger, find, isEmpty } from "lodash";

export default {
  data() {
    return {
      listCategory: [],
      listCuisine: [],
      listSubCategory: [],
      listAddOnCategory: [],
      itemId: null,
      formValid: true,
      pageLoading: false,
      images: [],
      item: {
        name_en: null,
        name_ch: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        reward: 0,
        reward_points: null,
        sale: 0,
        sale_amount: null,
        sale_duration: null,
        image: [],
        amount: null,
      },
    };
  },
  watch: {
    "item.sale"(param) {
      if (!param) {
        this.item.sale_amount = null;
        this.item.sale_duration = null;
      }
    },
    "item.reward"(param) {
      if (!param) {
        this.item.reward_points = null;
      }
    },
  },
  methods: {
    pageTitle() {
      if (this.itemId) {
        return "Update Item";
      }
      return "Create Item";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.itemForm.validate()) {
        _this.itemTab = "overview";
        return false;
      }

      try {
        _this.pageLoading = true;

        const form = {
          name_en: _this.item.name_en,
          name_ch: _this.item.name_ch,
          description: _this.item.description,
          cuisine: _this.item.cuisine,
          category: _this.item.category,
          sub_category: _this.item.sub_category,
          add_on_category: _this.item.add_on_category,
          reward: _this.item.reward,
          reward_points: _this.item.reward_points,
          sale: _this.item.sale,
          sale_amount: _this.item.sale_amount,
          sale_duration: _this.item.sale_duration,
          image: _this.images,
          amount: _this.item.amount,
        };

        if (_this.itemId) {
          const item = await UPDATE_ITEM(_this.itemId, form);
          _this.$router.replace({
            name: "item-detail",
            params: { id: item.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Item Updated Successfully." },
          ]);
        } else {
          const item = await CREATE_ITEM(form);
          _this.$router.replace({
            name: "item-detail",
            params: { id: item.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Item Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    removeImage(index) {
      console.log(this.images);
      this.images.splice(index, 1);
    },
    async getCategories() {
      const _this = this;
      try {
        _this.listCategory = await GET_CATEGORY();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    async getCuisines() {
      const _this = this;
      try {
        _this.listCuisine = await GET_CUISINE();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    async getSubCategories() {
      const _this = this;
      try {
        _this.listSubCategory = [];
        const category = find(_this.listCategory, { name: _this.item.category });
        const categoryId = isEmpty(category) === false ? category.id : 0;
        if (!categoryId) {
          return false;
        }
        _this.listSubCategory = await GET_SUB_CATEGORY({ parent: categoryId });
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    async getAddOnCategories() {
      const _this = this;
      try {
        _this.listAddOnCategory = await GET_ADD_ON_CATEGORY();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    async handleFilesUpload() {
      try {
        const { fileInput } = this.$refs;
        if (fileInput && fileInput.$refs) {
          const { input } = fileInput.$refs;
          if (input && input.files) {
            const formData = new FormData();
            for (let i = 0; i < input.files.length; i++) {
              formData.append(`files[${i}]`, input.files[i]);
            }
            this.pageLoading = true;
            const files = await UPDATE_FILES(formData);
            this.images = concat(this.images, files);
          }
        }
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },
    markAsPrimary(index) {
      for (let i = this.images.length - 1; i >= 0; i--) {
        this.images[i].primary = 0;
      }
      this.images[index].primary = 1;
    },
    async getItem() {
      try {
        const item = await GET_ITEM(this.itemId);

        this.item = {
          name_en: item.name_en,
          name_ch: item.name_ch,
          reward: toSafeInteger(item.reward),
          reward_points: item.reward_point,
          sale: toSafeInteger(item.sale),
          sale_amount: item.sale_amount,
          sale_duration: [item.sale_start, item.sale_end],
          description: item.description,
          cuisine: item.cuisine,
          category: item.category,
          sub_category: item.sub_category,
          add_on_category: item.add_on_category,
          image: [],
          amount: item.amount,
        };

        if (this.item.category) {
          this.getSubCategories();
        }

        this.images = item.images;

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Update", disabled: true },
          { text: item.barcode, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getCuisines();
    this.getAddOnCategories();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "item-update") {
      const { id } = params;
      if (id) {
        this.itemId = toSafeInteger(id);
        this.getItem();
      } else {
        this.goBack();
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  components: {
    AutoCompleteInput,
    NumberInput,
    CurrencyInput,
    DateRangePicker,
    ImageTemplate,
    TextAreaInput,
    TextInput,
  },
  computed: {
    itemTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.itemTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
  },
};
</script>
